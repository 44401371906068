import CallAction from "../component/webpage/callaction";
import CountUp from "../component/webpage/countup";
import Footer from "../component/webpage/footer";
import Header from "../component/webpage/header";
import Hizmetler from "../component/webpage/hizmetler";
import Progress from "../component/webpage/progress";
import Slider from "../component/webpage/slider";
import Why from "../component/webpage/why";
const WebPage = () => {
    return (

        <div className="App">
            <Header />
            <Slider />
            <Hizmetler />
            <CountUp />
            <Why />
            <CallAction />
            <Progress />
            <Footer />
        </div>
    );
};

export default WebPage;