const CountUp = () => {
    return (
        <div id="fun-facts" className="fun-facts section overlay">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                        {/* <!-- Start Single Fun --> */}
                        <div className="single-fun">
                            <i className="icofont-simple-smile"></i>
                            <div className="content">
                                <span className="counter">402953</span>
                                <p>Mutlu Müşteri</p>
                            </div>
                        </div>
                        {/* <!-- End Single Fun --> */}
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        {/* <!-- Start Single Fun --> */}
                        <div className="single-fun">
                            <i className="icofont icofont-listing-box"></i>
                            <div className="content">
                                <span className="counter">59703</span>
                                <p>Hizmet</p>
                            </div>
                        </div>
                        {/* <!-- End Single Fun --> */}
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        {/* <!-- Start Single Fun --> */}
                        <div className="single-fun">
                            <i className="icofont icofont-network"></i>
                            <div className="content">
                                <span className="counter">5332</span>
                                <p>Hizmet Noktası</p>
                            </div>
                        </div>
                        {/* <!-- End Single Fun --> */}
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        {/* <!-- Start Single Fun --> */}
                        <div className="single-fun">
                            <i className="icofont icofont-search-map"></i>
                            <div className="content">
                                <span className="counter">81</span>
                                <p>İlde</p>
                            </div>
                        </div>
                        {/* <!-- End Single Fun --> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CountUp;