import AboutUs from "../component/webpage/aboutus";
import Footer from "../component/webpage/footer";
import Header from "../component/webpage/header";
import Breadcrumb from "./breadcrumbs";

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        title: 'Kurumsal',
    },
    {
        title: 'Hakkımızda',
    },
];

const About = () => {
    return (
        <div className="App">
            <Header />
            <Breadcrumb title="Hakkımızda" items={BCrumb} />
            <AboutUs />
            <Footer />
        </div>
    );
};
export default About;
