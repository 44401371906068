import { Routes, Route } from 'react-router-dom';
import Homepage from './pages/webarea';
import Contact from './pages/contact';
import About from './pages/aboutus';

function App() { 
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Homepage />} />
        <Route exact path='*' element={<Homepage />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path='/hakkımızda' element={<About />} /> 
      </Routes> 
    </>
  );
}
export default App;
