const Why = () => {
    return (
        <div className="about-lists">
            <div className="section-title">
                <h2>Neden Mobil Assistance</h2>
            </div>
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                        <span>01</span>
                        <h4>Hızlı ve Yüksek Standartta Hizmet</h4>
                        <p>Dakikalar içerisinde müdahele ederek en kısa sürede çözümler üretiyoruz</p>
                    </div>

                    <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                        <span>02</span>
                        <h4>Güven Veren Sektör Deneyimi</h4>
                        <p>Sektörde lider olan altyapımızla en uygun ve kaliteli hizmeti sunuyoruz. </p>
                    </div>

                    <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="20">
                        <span>03</span>
                        <h4> Seçkin Tedarikçi Ağı</h4>
                        <p>Türkiye geneli seçkin tedarikçi ağımız ile her zaman yanınızdayız</p>
                    </div>

                    <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="30">
                        <span>04</span>
                        <h4>100 % Müşteri Memnuniyeti</h4>
                        <p>81 ilde 100% Müşteri memnuniyeti</p>
                    </div>

                    <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="40">
                        <span>05</span>
                        <h4>Kolay Ulaşılabilir</h4>
                        <p>7/24  Ulaşılabilir Çağrı merkezimizle her zaman yanınızdayız</p>
                    </div>

                    <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="50">
                        <span>06</span>
                        <h4>Güçlü ve Teknolojik Alt Yapı</h4>
                        <p>Kolay ve anında hizmet için sizde yerinizi şimdiden ayırtın</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Why;