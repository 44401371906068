const CallAction = () => {
    return (
        <section className="call-action overlay" data-stellar-background-ratio="0.5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="content">
                            <h2>Mobil Assistance Avantajlı paketlerinden yararlanmak için arayın 0850 255 02 03</h2>
                            <p>%100 Müşteri memnuniyeti hedefimiz ile sizde mutlu müşterilerimiz arasında yerinizi alın.</p>
                            <div className="button">
                                <a href="/#" className="btn">Paket Satın Al</a>
                                <a href="/#" className="btn">Neler Yapıyoruz <i className="fa fa-long-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default CallAction;