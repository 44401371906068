import ContactForm from "../component/webpage/contactform";
import Footer from "../component/webpage/footer";
import Header from "../component/webpage/header"; 
import Breadcrumb from "./breadcrumbs";

const BCrumb = [
    {
      to: '/',
      title: 'Anasayfa',
    },
    {
      title: 'İletişim',
    },
  ];
const Contact = () => {
    return ( 
        <div className="App">
            <Header /> 
            <Breadcrumb title="İletişim" items={BCrumb} />
            <ContactForm/>
            <Footer />
        </div>
    );
};

export default Contact;