const ContactForm = () => {
    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up"> 
                <div className="section-title"> 
                </div> 
                <div className="row">

                    <div className="col-lg-4 d-flex" data-aos="fade-up">
                        <div className="info-box">
                        <i className="icofont icofont-network"></i>
                            <h3>Adres</h3>
                            <p>Türkiyenin 81 İlindeyiz</p>
                        </div>
                    </div>

                    <div className="col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="100">
                        <div className="info-box">
                        <i className="icofont icofont-network"></i>
                            <h3>E-Posta</h3>
                            <p>info@mobilassistance.com.tr<br/>destek@mobilassistance.com.tr</p>
                        </div>
                    </div>

                    <div className="col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="200">
                        <div className="info-box ">
                        <i className="icofont icofont-network"></i>
                            <h3>Çağrı Merkezi</h3>
                            <p>7 Gün 24 Saat<br/>0850 255 02 03</p>
                        </div>
                    </div>

                    <div className="col-lg-12" data-aos="fade-up" data-aos-delay="300">
                        {/* <form action="#" method="post" role="form" className="php-email-form"> */}
                            <div className="row">
                                <div className="col-lg-6 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Ad Soyad" required></input>
                                </div>
                                <div className="col-lg-6 form-group">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="E-Posta" required></input>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Telefon" required></input>
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" rows="5" placeholder="Mesajınız" required></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center"><a href="/#" className="btn">Gönder</a></div>
                        {/* </form> */}
                    </div>

                </div>

            </div><br></br>
        </section>
        
    );
};
export default ContactForm;