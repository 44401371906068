import resim from '../../assets/img/blog1.jpg'
import resim2 from '../../assets/img/blog2.jpg'
import resim3 from '../../assets/img/blog3.jpg'
const AboutUs = () => {
    return (
        <section className="news-single section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="single-main">
                                    <div className="news-head">
                                        <img src={resim} alt="Mobil Assistance Kurumsal"/>
                                    </div>
                                    <h1 className="news-title text-center"> Mobil Assistance Hakkında</h1>
                                    <div className="news-text">
                                        <p>Mobil assistance, insanlara günlük yaşamın engebeli yolculuklarının üstesinden gelmeleri için imkan yaratan; güven, özen ve empati ile yoğrulmuş en son teknolojilerle, iyi düşünülmüş hizmetler ve teklifler sunan assist destek sistemidir. Acil bir durum olsun veya olmasın, müşterilerimizin omuzlarındaki yükü hafifletecek gerçek bir Assist olmak için yola çıktık.</p>
                                        <p>İnsan odaklı ve hizmet merkezli bakış açısıyla harekete geçen Mobil Assistance karmaşık prosedür ve taahhütler yerine bağlayıcı olmayan, daha basit ve kişiselleştirilmiş hizmetlere odaklanarak kullanıcılarını en hızlı şekilde en kaliteli çözüme ulaştırıp yaşam standartlarını artırmayı hedefler.</p>
                                        <div className="image-gallery">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <div className="single-image">
                                                    <img src={resim2} alt="Mobil Assistance Kurumsal"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <div className="single-image">
                                                    <img src={resim3} alt="Mobil Assistance Kurumsal"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <blockquote className="overlay">
                                            <p>Türkiye’nin 81 ilinde 4000’i aşkın hizmet sağlayıcısını aynı çatı altında buluşturmayı başardık. Söz konusu tedarikçilerimiz ve hizmet sağlayan paydaşlarımızla karşılıklı fayda anlayışı içinde tek ortak hedefimiz hızlı, kaliteli ve hatasız hizmet sunmaktır.</p>
                                        </blockquote>
                                        <p>Tüm sorunları ortadan kaldırarak yola devam etmeniz için buradayız…
</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    );
};
export default AboutUs;