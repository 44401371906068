const Footer = () => {
    return (
        <footer id="footer" className="footer ">
            {/* <!-- Footer Top --> */}
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="single-footer">
                                <h2>Hakkımızda</h2>
                                <p>Lorem ipsum dolor sit am consectetur adipisicing elit do eiusmod tempor incididunt ut labore dolore magna.</p>
                                {/* <!-- Social --> */}
                                <ul className="social">
                                    <li><a href="/#"><i className="icofont-facebook"></i></a></li>
                                    <li><a href="/#"><i className="icofont-google-plus"></i></a></li>
                                    <li><a href="/#"><i className="icofont-twitter"></i></a></li>
                                    <li><a href="/#"><i className="icofont-vimeo"></i></a></li>
                                    <li><a href="/#"><i className="icofont-pinterest"></i></a></li>
                                </ul>
                                {/* <!-- End Social --> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="single-footer f-link">
                                <h2>Hızlı Linkler</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <ul>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>Anasayfa</a></li>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>Hakkımızda</a></li>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>Hizmetlerimiz</a></li>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>Kalite Belgelerimiz</a></li>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>Tanıtım Videomuz</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <ul>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>Assistance Hizmeti</a></li>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>Yol Yardım</a></li>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>İkame Araç</a></li>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>Çağrı Merkezi Hizmeti</a></li>
                                            <li><a href="/#"><i className="fa fa-caret-right" aria-hidden="true"></i>Kişiye Özel Paketler</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="single-footer">
                                <h2>Haber Bülteni</h2>
                                <p>Yeni kampanyalardan haberdar olmak için bültenimize kayıt olun</p>
                                <form action="mail/mail.php" method="get" target="_blank" className="newsletter-inner"> 
                                    <input name="email" placeholder="E-Posta Adresiniz" className="common-input" onfocus="this.placeholder = ''"
                                        onblur="this.placeholder = 'Your email address'" required="" type="email"></input>
                                        <button className="button"><i className="icofont icofont-paper-plane"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--/ End Footer Top --> */}
            {/* <!-- Copyright --> */}
            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="copyright-content">
                                <p>© Copyright 2024  |  All Rights Reserved by Mobil Assistance </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--/ End Copyright --> */}
        </footer>
    );

};
export default Footer;