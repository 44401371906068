const Progress = () => {
    return (
        <section id="services" className="appservices">
            <div className="container">
                <div className="section-title" data-aos="fade-up">
                    <h2>Hizmetler</h2>
                    <p>Magnam dolores commodi suscipit eius consequatur ex aliquid fug</p>
                </div>

                <div className="row">
                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-8 mb-lg-0">
                        <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                            <div className="icon"><i className="icofont icofont-search-document"></i></div>
                            <h4 className="title"><a href="/">Sözleşme Ara</a></h4>
                            <p className="description">Sözleşme detaylarınızı görmek için</p>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                        <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                            <div className="icon"><i className="icofont icofont-cart-alt"></i></div>
                            <h4 className="title"><a href="/">Paket Satınal</a></h4>
                            <p className="description">Mobil Assistance avantajlı paketlerinden faydalanmak için</p>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                        <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                            <div className="icon"><i className="icofont icofont-users-social"></i></div>
                            <h4 className="title"><a href="/">Bayi Başvurusu</a></h4>
                            <p className="description">Mobil Assistance Ailesinin bir parçası olmak için</p>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                        <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                            <div className="icon"><i className="icofont icofont-tow-truck"></i></div>
                            <h4 className="title"><a href="/">Tedarikçi Başvurusu</a></h4>
                            <p className="description">Tedarikçimiz olmak için</p>
                        </div>
                    </div>

                </div>

            </div> <br></br>
        </section>

    );
};
export default Progress;