import logo from '../../assets/images/logo/MobilAssistance.png'

const Header = () => { 
    return (
        <header className="header" >
            <div className="topbar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-5 col-12">
                            {/* <!-- Contact --> */}
                            <ul className="top-link">
                                <li><a href="/#">Blog</a></li>
                                <li><a href="/#">Sosyal Medyalar</a></li>
                            </ul>
                            {/* <!-- End Contact --> */}
                        </div>
                        <div className="col-lg-6 col-md-7 col-12">
                            {/* <!-- Top Contact --> */}
                            <ul className="top-contact">
                                <li><i className="fa fa-phone"></i>+0850 255 02 03</li>
                                <li><i className="fa fa-envelope"></i><a href="mailto:info@mobilassistance.com.tr">info@mobilassistance.com.tr
                                </a></li>
                            </ul>
                            {/* <!-- End Top Contact --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Topbar --> */}
            {/* <!-- Header Inner --> */}
            <div className="header-inner">
                <div className="container">
                    <div className="inner">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-12">
                                {/* <!-- Start Logo --> */}
                                <div className="logo">
                                    <a href="/"><img src={logo} alt="Mobil Assistance Yol Yardım Hizmetleri" width={180} /></a>
                                </div>
                                {/* <!-- End Logo --> */}
                                {/* <!-- Mobile Nav --> */}
                                <div className="mobile-nav"></div>
                                {/* <!-- End Mobile Nav --> */}
                            </div>
                            <div className="col-lg-7 col-md-9 col-12">
                                {/* <!-- Main Menu --> */}
                                <div className="main-menu">
                                    <nav className="navigation">
                                        <ul className="nav menu">
                                            <li><a href="/">Anasayfa</a></li>
                                            <li><a href="/#">Kurumsal<i className="icofont-rounded-down"></i></a>
                                                <ul className="dropdown">
                                                    <li><a href="/hakkımızda">Hakkımızda</a></li>
                                                    <li><a href="404.html">Kalite Belgelerimiz</a></li>
                                                    <li><a href="404.html">Tanıtım Videosu</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="/#">Hizmetlerimiz<i className="icofont-rounded-down"></i></a>
                                                <ul className="dropdown">
                                                    <li><a href="404.html">Assistance Hizmeti</a></li>
                                                    <li><a href="404.html">-Yol Yardım</a></li>
                                                    <li><a href="404.html">-İkame Araç</a></li>
                                                    <li><a href="404.html">Çağrı Merkezi Hizmeti</a></li>
                                                    <li><a href="404.html">Kişiye Özel Hizmet Paketleri</a></li>
                                                </ul>
                                            </li> 
                                            <li><a href="/contact">İletişim</a></li>
                                        </ul>
                                    </nav>
                                </div>
                                {/* <!--/ End Main Menu --> */}
                            </div>
                            <div className="col-lg-2 col-12">
                                <div className="get-quote">
                                    <a href="/#" className="btn">Online İşlemler</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;