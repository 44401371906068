import Slider1 from '../../assets/img/slider.jpg'
import Slider2 from '../../assets/img/slider2.jpg'
import Slider3 from '../../assets/img/slider3.jpg'

const Slider = () => {
    return (
        <section className="slider">
            <div className="hero-slider">
                {/* <!-- Start Single Slider --> */}
                <div className="single-slider" style={{ backgroundImage: `url(${Slider1})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="text">
                                    <h1>Güvenilir marka <span>Mobil Assistance</span></h1>
                                    <p>%100 Müşteri memnuniyeti hedefimizle sektörün lideri olmaya devam ediyoruz. </p>
                                    <div className="button">
                                        <a href="/#" className="btn">Neden Mobil Assistance ?</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Single Slider --> */}
                {/* <!-- Start Single Slider --> */}
                <div className="single-slider" style={{ backgroundImage: `url(${Slider2})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="text">
                                    <h1>We Provide <span>Medical</span> Services That You Can <span>Trust!</span></h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed nisl pellentesque, faucibus libero eu, gravida quam. </p>
                                    <div className="button">
                                        <a href="/#" className="btn">Get Appointment</a>
                                        <a href="/#" className="btn primary">About Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Start End Slider --> */}
                {/* <!-- Start Single Slider --> */}
                <div className="single-slider" style={{ backgroundImage: `url(${Slider3})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="text">
                                    <h1>We Provide <span>Medical</span> Services That You Can <span>Trust!</span></h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed nisl pellentesque, faucibus libero eu, gravida quam. </p>
                                    <div className="button">
                                        <a href="/#" className="btn">Get Appointment</a>
                                        <a href="/#" className="btn primary">Conatct Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Single Slider --> */}
            </div>
        </section>
    );
};

export default Slider;