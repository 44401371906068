
const Hizmetler = () => {
    return (
        <section className="schedule">
            <div className="container">
                <div className="schedule-inner">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 ">
                            {/* <!-- single-schedule --> */}
                            <div className="single-schedule first">
                                <div className="inner">
                                    <div className="icon">
                                        <i className="icofont-support"></i>
                                    </div>
                                    <div className="single-content">
                                        <h4>Assistance Hizmetleri</h4>

                                        <p>Aracınızla ilgili başınıza gelebilecek her türlü sorunda Yol arkadaşınız yanınızda.
                                            <br></br>
                                            Çekme, kurtarma
                                            <br></br>
                                            Lastik değişimi
                                            <br></br>
                                            Yakıt bitmesi
                                            <br></br>
                                            ve daha fazlası</p>
                                        <a href="/#">Daha Fazlası İçin<i className="fa fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            {/* <!-- single-schedule --> */}
                            <div className="single-schedule middle">
                                <div className="inner">
                                    <div className="icon">
                                        <i className="icofont-phone"></i>
                                    </div>
                                    <div className="single-content">
                                        <h4>Çağrı Merkezi Hizmetleri</h4>
                                        <p>7 gün 24 saat hizmet veren Çağrı Merkezimiz ve tüm ülkede yaygın anlaşmalı hizmet birimleri ağımız ile otomotiv markaları, araç kiralama şirketleri, Sigorta şirketleri, Assistance firmaları için yol yardım, müşteri ilişkileri ve anket hizmetleri veriyoruz.</p>
                                        <a href="/#">Daha Fazlası İçin<i className="fa fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-12">
                            {/* <!-- single-schedule --> */}
                            <div className="single-schedule last">
                                <div className="inner">
                                    <div className="icon">
                                        <i className="icofont-cart"></i>
                                    </div>
                                    <div className="single-content">
                                        <h4>Kişiye Özel Paketler</h4>
                                        <p>İnsan odaklı ve hizmet merkezli bakış açısıyla Mobil Assistance karmaşık prosedür ve taahhütler yerine bağlayıcı olmayan, daha basit ve kişiselleştirilmiş hizmetlere odaklanarak en hızlı ve en kaliteli çözüme ulaştırıp yaşam standartlarını artırmayı hedefler.</p>
                                        <a href="/#">Daha Fazlası İçin<i className="fa fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hizmetler;